<template>
    <div class="error">
        <h1>Pagina no encontrada (Error 404)</h1>
        <h2>Parece que algo fallo y no encontramos la pagina que estas buscando, inténtalo de nuevo.</h2>
    </div>
</template>

<style scoped>
h1, h2 {
    text-align: center;
    margin: 30px auto;
}
</style>